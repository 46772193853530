@require '/src/dev'
/*


        List styles


*/

.list
    list()
    &--vertical
        & > li
            position: relative
    &--horizontal
        & > li
            display: inline